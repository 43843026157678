<template>
  <div id="TasanteaProductDetail">
    <div class="banner">
      <img src="../../assets/product/banner_tasantea.jpg" alt="" srcset="" />
    </div>
    <!-- 他山集高山系列 - 明前绿茶 -->
    <div class="tasnatea" v-if="product_id == 1">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/d_mingqianlvcha.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.高山系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">明前绿茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">产地：金沙县岚头镇，</div>
                <div class="dec_item">海拔：约1000米，</div>
                <div class="dec_item">口感：鲜醇甘爽。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">千米海拔，贵州高山茶园，</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">中火提香，明前炒青绿茶，</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">滋味浓醇，香高持久。</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：37.5g(2.5g*15)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集高山系列 - 陈香普洱 -->
    <div class="tasnatea" v-if="product_id == 2">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/d_chenxiangpuer.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.高山系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">陈香普洱</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">产地：勐海县布朗山，</div>
                <div class="dec_item">海拔：约1500米，</div>
                <div class="dec_item">口感：陈香浓郁 ，浓醇回。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">千米海拔，高山茶园头春普洱，</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">科学控温，近60天充分发酵熟茶，</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">浓醇回甘，持久耐泡。</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：37.5g(2.5g*15)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集高山系列 - 青心乌龙 -->
    <div class="tasnatea" v-if="product_id == 3">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/d_qingxinwulong.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.高山系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">青心乌龙</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">产地：云南腾冲高黎贡山，</div>
                <div class="dec_item">海拔：约2000米，</div>
                <div class="dec_item">口感：鲜爽甘醇，清新花果香。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">两千米海拔，采摘高山茶园初夏鲜叶，</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">软枝乌龙品种，轻发酵轻焙火，</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">鲜爽甘醇，清新花果香。</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：37.5g(2.5g*15)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集高山系列 - 蜜香红茶 -->
    <div class="tasnatea" v-if="product_id == 4">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/d_mixianghongcha.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.高山系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">蜜香红茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">产地：云南腾冲高黎贡山，</div>
                <div class="dec_item">海拔：约2000米，</div>
                <div class="dec_item">口感：香高味醇 ，焦糖香浓。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">两千米海拔，采摘高山茶园初夏鲜叶，</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">软枝乌龙品种，重焙火全发酵，</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">香高味醇 ，焦糖香浓。</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：37.5g(2.5g*15)</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 他山集经典系列 - 大红袍 -->
    <div class="tasnatea" v-if="product_id == 5">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/d_dahongpao.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.经典系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">大红袍</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">产地：福建南平市松溪县，</div>
                <div class="dec_item">海拔：约600米，</div>
                 <div class="dec_item">口感：兰香馥郁幽长。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">精选福建松溪优质乌龙茶品种，</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">三红七绿，传统乌龙茶工艺，</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">岩骨花香，持久耐泡，回甘生津。</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：24g(2gx12)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集经典系列 - 金骏眉 -->
    <div class="tasnatea" v-if="product_id == 6">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/d_jinjunmei.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.经典系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">金骏眉</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">产地：福建南平市松溪县，</div>
                <div class="dec_item">海拔：约600米，</div>
                <div class="dec_item">口感：花果甜香，薯香蜜韵。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">精选福建松溪野生茶树群体种，</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">21世纪创新工艺小种红茶，</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">金毫显露，芽头挺拔，鲜爽回甘。</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：24g(2gx12)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集经典系列 - 白牡丹 -->
    <div class="tasnatea" v-if="product_id == 7">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/d_baimudan.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.经典系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">白牡丹</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">产地：福建南平市松溪县，</div>
                <div class="dec_item">海拔：约600米，</div>
                <div class="dec_item">口感：鲜爽豆奶香，</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">精选福建松溪福安大白茶，</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">不炒不揉，自然晾晒，</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">鲜爽甘醇，豆香清幽。</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：24g(2gx12)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集经典系列 - 龙井茶 -->
    <div class="tasnatea" v-if="product_id == 21">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/d_longjincha.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.经典系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">龙井茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">产地：杭州市，</div>
                <div class="dec_item">海拔：约400米，</div>
                <div class="dec_item">口感：炒豆香。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">杭州钱塘龙井，</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">谷雨春茶老龙井，</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">鲜爽回甘，炒豆香</div>
              </div>
            </div>
             <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：24g(2gx12)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集经典系列 - 东方美人 -->
    <div class="tasnatea" v-if="product_id == 22">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-22.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.经典系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">东方美人</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">1500米海拔，</div>
                <div class="dec_item">云南临沧云顶筑巢茶庄园</div>
                <div class="dec_item">白毫乌龙茶。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">女皇赐名，美如佳人</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">熟果蜜香，醇和甜润</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">皇室钟爱，独特口感</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：20g(2gx10)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集经典系列 - 福鼎白茶 -->
    <div class="tasnatea" v-if="product_id == 23">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-23.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.经典系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">福鼎白茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">700米海拔，</div>
                <div class="dec_item">福建松溪轻发酵福鼎大白茶。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">茶香清新，滋味鲜美</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">茶性清凉，消暑降温</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">天然养颜，清新健康</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：20g(2gx10)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集果味系列 - 葡萄乌龙 -->
    <div class="tasnatea" v-if="product_id == 24">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-23.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.果味系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">葡萄乌龙</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">【茶叶】福建安溪铁观音</div>
                <div class="dec_item">【配料】新疆饱满绿提、脱水烘干</div>
                <div class="dec_item">【口感】酸甜可口、鲜爽回甘</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">优质铁观音拼配新疆绿提干</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">葡萄香溢、茶香回甘</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">香气交错、清醇甘甜</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：30g(3gx10)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集花果系列 - 桂花乌龙 -->
    <div class="tasnatea" v-if="product_id == 25">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-24.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.花果系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">桂花乌龙</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">【茶叶】福建安溪铁观音</div>
                <div class="dec_item">【配料】广西金桂</div>
                 <div class="dec_item">【口感】淡雅甘冽/口颊留香</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">金桂馥郁、乌龙回甘</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">一杯桂花乌龙</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">清新口气、唇颊留香</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：25g(2.5gx10)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集果味系列 - 白桃乌龙 -->
    <div class="tasnatea" v-if="product_id == 8">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-8.jpg"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.果味系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">白桃乌龙</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">【茶叶】[半发酵]福建铁观音</div>
                <div class="dec_item">【配料】广东揭阳白桃、蜜渍出干</div>
                <div class="dec_item">【口感】爽口甜沁、浓郁蜜桃香</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">原叶乌龙拼配蜜渍白桃</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">白桃香甜、茶味鲜爽</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">果香茶香完美融合</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：30g(3gx10)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集果味系列 - 蜜桃乌龙 -->
    <div class="tasnatea" v-if="product_id == 27">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/d_mitaowulong.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.果味系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">蜜桃乌龙</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
               <div class="dec_item">【茶叶】[半发酵]福建铁观音</div>
                <div class="dec_item">【配料】安徽砀山水蜜桃、糖渍出干</div>
                <div class="dec_item">【口感】鲜甜桃香、清甜茶味</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">精选优质福建安溪铁观音</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">安徽砀山糖渍桃干</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">茶味甘醇、桃香四溢</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：30g(3gx10)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集果味系列 - 椰子乌龙 -->
    <div class="tasnatea" v-if="product_id == 9">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-9.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.果味系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">椰子乌龙</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
               <div class="dec_item">【茶叶】[半发酵]福建安溪西坪镇铁观音</div>
                <div class="dec_item">【配料】广东下川岛烘烤椰肉干</div>
                <div class="dec_item">【口感】椰奶香浓、茶味鲜醇</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">精选优质福建安溪铁观音</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">大块果粒、烘烤椰肉干</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">茶味鲜醇、椰香浓郁</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：30g(3gx10)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集汉方养园系列 - 冬瓜荷叶 -->
    <div class="tasnatea" v-if="product_id == 10">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-10.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.汉方养园系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">冬瓜荷叶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">海南黑皮冬瓜，湖北晒干荷叶，</div>
                <div class="dec_item">拼配决明子、橘皮、玫瑰花蕾，</div>
                <div class="dec_item">收获滋养好茶。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">消油解腻，曲线动人香</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">六味食材，汉方调配</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">草本天然，轻肠轻体</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：75g(5gx15)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集汉方养园系列 - 桂圆红枣 -->
    <div class="tasnatea" v-if="product_id == 11">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-11.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.汉方养园系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">桂圆红枣</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">云南后发酵普洱茶，</div>
                <div class="dec_item">新疆红枣、福建桂圆、宁夏枸杞，</div>
                <div class="dec_item">三宝一茶，清甜好味。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">创新配方，普洱打底</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">四味食材，无需妆容</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">甜润柔和，枣香浓郁</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：75g(5gx15)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集汉方养园系列 - 红豆薏米 -->
    <div class="tasnatea" v-if="product_id == 12">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-12.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.汉方养园系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">红豆薏米</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">黑龙江赤小豆，福建蒲城薏米，</div>
                <div class="dec_item">搭配大枣、熟普洱，芡实，</div>
                <div class="dec_item">天然食养，轻体除湿。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">普洱打底，利水除湿</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">清甜好味，轻盈体态</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">五味食材，远离湿态</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：75g(5gx15)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集汉方养园系列 - 金桂雪梨 -->
    <div class="tasnatea" v-if="product_id == 13">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-13.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.汉方养园系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">金桂雪梨</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">福建福鼎贡眉与浙江毛峰，</div>
                <div class="dec_item">拼配桂花、雪梨丁、白瓜粒，</div>
                <div class="dec_item">止咳润肺，清热去火。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">白茶基底，去燥养身</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">甘甜花香，清爽茶味</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">五味食材，沁润身心</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：75g(5gx15)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集汉方养园系列 - 菊花决明子 -->
    <div class="tasnatea" v-if="product_id == 14">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-14.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box dec_box_4_line">
          <div class="series_title">
            <span>他山集.汉方养园系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">菊花决明子</div>
          <div class="dec_box ">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">河南焦作胎菊，</div>
                <div class="dec_item">安徽优质决明子，</div>
                <div class="dec_item">融入枸杞、牛蒡根，</div>
                <div class="dec_item">奉上鲜爽甜醇的茶味。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">清凉清心，顾盼生辉</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">五味食材，绿茶打底</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">清甜好味，熬夜必备</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：75g(5gx15)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集组合系列 - 桃花未央 -->
    <div class="tasnatea" v-if="product_id == 26">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-25.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.组合系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">桃花未央</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">他山集、天猫、盘子女人坊三方联名，</div>
                <div class="dec_item">拟汉服形态茶包</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">首款他山集三方联名茶包</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">唐风小衣柜，汉服小茶包</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">穿盛世华服，享盛唐茶宴</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：30g(3gx10)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集组合系列 - 10口味组合装 -->
    <div class="tasnatea" v-if="product_id == 15">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-15.jpg"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.组合系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">10口味组合装</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">体验10种口味尝鲜装，</div>
                <div class="dec_item">轻松找到你喜欢的那款茶，</div>
                <div class="dec_item">冷泡热泡随心畅饮。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">10种口味，一次体验</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">独立包装，即时随饮</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">携带方便，随手可拿</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：21g(3g+2gx9)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集组合系列 - 21天打卡装 -->
    <div class="tasnatea" v-if="product_id == 16">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-16.jpg"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box dec_box_4_line">
          <div class="series_title">
            <span>他山集.组合系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">21天打卡装</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">0糖0脂0卡，周期控糖，</div>
                <div class="dec_item">为健身人士打造，奶茶级口感，</div>
                <div class="dec_item">21天养成好习惯。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品卖点</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">0糖0脂0卡，周期控糖</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">奶茶级口感，好喝无负担</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">每日改变，21天养成好习</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：42g(2gx21)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集组合系列 - 红茶大满贯 -->
    <div class="tasnatea" v-if="product_id == 17">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-17.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.组合系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">红茶大满贯</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">他山集首款新年礼盒，</div>
                <div class="dec_item">汇集世界五款精选红茶</div>
                <div class="dec_item">打造有温度的女性礼物。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">世界五大经典红茶，一次品尝</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">首款红茶礼盒，暖胃养身</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">女性茶饮，传递“她力量”</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：60g(2gx30)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集组合系列 - 企业量贩装 -->
    <div class="tasnatea" v-if="product_id == 18">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-18.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.组合系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">企业量贩装</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">精选5种高山云雾好茶，</div>
                <div class="dec_item">100袋超值企业量贩装，</div>
                <div class="dec_item">满足企业办公、待客需求。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">100袋量贩，口粮常备</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">独立小包，冲饮方便</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">家庭饮用，企业待客</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content" style="height:278px;">净含量：250g(2.5gx100)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集组合系列 - 夏日果果茶 -->
    <div class="tasnatea" v-if="product_id == 19">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-19.jpg"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.组合系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">夏日果果茶</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">白桃、葡萄、荔枝、柠檬、椰子，</div>
                <div class="dec_item">集结5种水果风味，清香甜润，</div>
                <div class="dec_item">给你夏日果味茶香味道。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">5种风味，缤纷果香</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">真实果粒，补充VC</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">原叶好茶，口感香醇</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：45g(3gx15)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集组合系列 - 夏日冷萃茶 -->
    <div class="tasnatea" v-if="product_id == 20">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/2-20.jpg"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box dec_box_4_line">
          <div class="series_title">
            <span>他山集.组合系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">半夏冷萃</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">白桃乌龙、青心乌龙、茉莉绿茶、</div>
                <div class="dec_item">玫瑰滇红、东方美人，集5种口味，</div>
                <div class="dec_item">低温萃取，口感清凉甘甜。</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">5种口味，任意冰爽</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">原叶袋泡，健康方便</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">冷萃组合，冷热皆宜</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content" style="height:327px">净含量：40.5g(3gx6+2.5gx9)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集花果系列 - 玫瑰滇红 -->
    <div class="tasnatea" v-if="product_id == 28">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/d_meiguidianhong.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.花果系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">玫瑰滇红</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">【茶叶】云南临沧滇红</div>
                <div class="dec_item">【配料】法国路易十四墨红玫瑰</div>
                <div class="dec_item">【口感】丝滑甜醇、花香四溢</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">路易十四墨红玫瑰浓香</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">云南大叶种茶树一芽二叶</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">滇红甜润、滋味甘冽</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：25g(2.5gx10)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他山集花果系列 - 陈皮普洱 -->
    <div class="tasnatea" v-if="product_id == 29">
      <div class="detail_content">
        <div class="p_img">
          <img
            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/v2/d_chenpipuer.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="p_dec_box">
          <div class="series_title">
            <span>他山集.花果系列</span>
            <img src="../../assets/productDetail/b_a.png" alt="" srcset="" />
          </div>
          <div class="title">陈皮普洱</div>
          <div class="dec_box">
            <div class="dec_item_left">
              <div class="dec_title">产品介绍</div>
              <div class="dec_content">
                <div class="dec_item">【茶叶】云南临沧滇红</div>
                <div class="dec_item">【配料】五年新会陈皮</div>
                <div class="dec_item">【口感】浓醇回甘、柑香馥郁</div>
              </div>
            </div>
            <div class="dec_item_right">
              <div class="dec_title">产品特性</div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">甄选五年新会二红柑</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">新工艺发酵布朗山熟普洱</div>
              </div>
              <div class="dec_item">
                <div class="dec_icon">
                  <img
                    src="../../assets/productDetail/icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="dec_item_content">滋味浓醇、耐泡度高</div>
              </div>
            </div>
            <div class="dec_item_mid">
              <div class="dec_title">产品规格</div>
              <div class="dec_content">净含量：25g(2.5gx10)</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PCConncatUs/>
  </div>
</template>

<script>
import PCConncatUs from "@/components/PCConncatUs.vue";
export default {
  name: "TasanteaProductDetail",
  components: {
    PCConncatUs
  },
  props: {},
  setup() {},
  data() {
    return {
      product_id: "1", //产品ID
    };
  },
  methods: {},
  created() {
    this.product_id = this.$route.query.pid;
  },
  mounted() {},
};
</script>

<style scoped lang="less">
#TasanteaProductDetail {
  width: 100%;
  .banner {
    width: 1006px;
    img {
      width: 100%;
    }
  }
  .tasnatea {
    .detail_content {
      /* width: 857px; */
      width: 85.12%;
      margin: 40px auto 0 auto;
      overflow: hidden;
      .p_img {
        /* width: 320px; */
        width: 37.34%;
        float: left;
        img {
          width: 100%;
        }
      }
      .p_dec_box {
        /* width: 363px; */
        float: left;
        /* margin-left: 120px; */
        margin-left: 4%;
        .series_title {
          width: 363px;
          font-size: 36px;
          font-weight: bold;
          color: #45B035;
          /* margin-top: 64px; */
          position: relative;
          text-align: center;
          img {
            width: 100%;
            height: 17px;
            position: absolute;
            left: 0;
            bottom: -4px;
            opacity: 0.8;
          }
        }
        .title {
          font-size: 26px;
          font-weight: 400;
          color: #121212;
          margin-top: 22px;
        }
        .dec_box {
          width: 100%;
          margin-top: 48px;
          overflow: hidden;
          .dec_item_left {
            min-width: 132px;
            float: left;
            .dec_title {
              width: 46px;
              height: 132px;
              font-size: 22px;
              font-weight: 500;
              color: #ffffff;
              float: left;
              writing-mode: vertical-rl; //垂直方向，从左向右
              line-height: 46px;
              letter-spacing: 5px;
              text-align: center;
              background: #45B035;
            }
            .dec_content {
              /* width: 100px; */
              height: 100%;
              float: left;
              margin-left: 14px;
              writing-mode: vertical-lr; //垂直方向，从左向右
              line-height: 24px;
              letter-spacing: 5px;
              /* text-align: center; */
            }
          }
          .dec_item_right {
            float: left;
            margin-left: 25px;
            .dec_title {
              width: 46px;
              height: 132px;
              font-size: 22px;
              font-weight: 500;
              color: #ffffff;
              float: left;
              writing-mode: vertical-rl; //垂直方向，从左向右
              line-height: 46px;
              letter-spacing: 5px;
              text-align: center;
              background: #45B035;
            }
            .dec_item {
              width: 30px;
              float: left;
              margin-left: 14px;

              .dec_icon {
                width: 24px;
                height: 24px;
                margin-left: 5px;
                img {
                  width: 24px;
                  height: 24px;
                }
              }
              .dec_item_content {
                width: 100%;
                writing-mode: vertical-lr; //垂直方向，从左向右
                line-height: 27px;
                letter-spacing: 5px;
                font-size: 16px;
                margin-top: 6px;
                font-weight: 400;
                color: #121212;
                text-align: center;
              }
            }
          }
          .dec_item_mid {
            min-width: 84px;
            float: left;
            margin-left: 25px;
            .dec_title {
              width: 46px;
              height: 132px;
              font-size: 22px;
              font-weight: 500;
              color: #ffffff;
              float: left;
              writing-mode: vertical-rl; //垂直方向，从左向右
              line-height: 46px;
              letter-spacing: 5px;
              text-align: center;
              background: #45B035;
            }
            .dec_content {
              /* width: 64px; */
             /*  height: 240px; */
              float: left;
              margin-left: 14px;
              writing-mode: vertical-lr; //垂直方向，从左向右
              line-height: 24px;
              letter-spacing: 5px;
              /* text-align: center; */
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 968px) and (max-width: 1368px) {
    .banner {
      width: 774px;
    }
    .tasnatea {
      .detail_content {
        width: 100%;
        /* .p_dec_box {
          margin-left:40px;
        } */
        .dec_box_4_line{
          margin-left:16px !important;
        }
      }
    }
  }
}
</style>
